import { Col, Row } from 'react-bootstrap';
import { SiCircle } from 'react-icons/si';
import { useCallback, useEffect, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { toast } from 'react-toastify';
import { FaCheckCircle } from 'react-icons/all';
import { useParams } from 'react-router-dom';
import { Header } from '../../Trilhas/Kandan/styles';
import { BoxItemGame } from '../../../components/BoxItemGame';
import api from '../../../services/api';
import { IUnitChecklist } from '../../../interfaces/business-units/checklists/IUnitChecklist';
import { IUnitChecklistItem } from '../../../interfaces/business-units/checklists/item/IUnitChecklistItem';
import { ModalFormChecklist } from './ModalFormChecklist';
import { ModalFormChecklistItens } from './Modal-Form-Checklist-Itens';
import { Button } from '../../../components/Button';
import { ModalFormAgendamento } from '../../ChecklistCategories/ModalFormAgendamento';

const swalOptions: SweetAlertOptions = {
  title: 'Deseja realmente excluir?',
  text: 'Você não será capaz de reverter isso.',
  icon: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#ef5350',
  cancelButtonColor: '#757575',
  confirmButtonText: 'Sim, excluir',
  cancelButtonText: 'Cancelar',
  denyButtonColor: '#757575',
};

export const ChecklistsKanban = () => {
  const CHECKLIST_UNIDADE_BASE_URL = '/checklistunidade/';
  const { id: unidadeId } = useParams<{ id: string }>();

  const [unitChecklists, setUnitChecklists] = useState<IUnitChecklist[]>([]);
  const [unitChecklistItems, setUnitChecklistItems] = useState<IUnitChecklistItem[]>([]);
  const [selectedChecklist, setSelectedChecklist] = useState<any>();
  const [selectedChecklistItem, setSelectedChecklistItem] = useState<IUnitChecklistItem>();
  const [showModalForm, setShowModalForm] = useState(false);
  const [showModalFormChecklistItem, setShowModalFormChecklistItem] = useState(false);
  const [isOpenModalFormAgendamento, setIsOpenModalFormAgendamento] = useState<boolean>(false);
  const [showModalFormViewOnly, setShowModalFormViewOnly] = useState(false);

  const getExtraInfoLabel = (jaLancado?: boolean, agendado?: boolean): string => {
    let result = '';
    if (jaLancado) {
      result = 'Checklist lançado';
    } else if (agendado) {
      result = 'Checklist agendado';
    }
    return result;
  };

  const mapChecklistBox = (checklists: IUnitChecklist[]): IUnitChecklist[] => checklists
    .map((_checklist) => ({
      ..._checklist,
      extraInfo: getExtraInfoLabel(_checklist.jaLancado, _checklist.agendado),
      canEdit: !((_checklist.jaLancado || _checklist.agendado) ?? false),
      canDuplicate: !((_checklist.jaLancado || _checklist.agendado) ?? false),
      canPreview: (_checklist.jaLancado || _checklist.agendado) ?? false,
    }));

  const getChecklistsUnidade = () => {
    api.get(`checklistunidade/categoria/${unidadeId}`)
      .then(({ data }) => {
        setUnitChecklists(mapChecklistBox(data.data));
      })
      .catch(() => {
        toast.error('Erro ao buscar lista de categorias');
      });
  };

  useEffect(() => {
    try {
      getChecklistsUnidade();
    } catch (error: any) {
      toast.error(error.message);
    }
  }, []);

  const fetchUnitChecklistItems = (unitChecklistId: number) => {
    api.get(`${CHECKLIST_UNIDADE_BASE_URL}${unitChecklistId}/itens`).then((response) => {
      const sortedResponse = response.data.data.sort((a: any, b: any) => a.ordem - b.ordem);
      setUnitChecklistItems(sortedResponse);
    });
  };

  const handleOnChecklistSelect = useCallback(
    (itemId: number) => {
      const selected = unitChecklists.find((checklist) => checklist.id === itemId);
      setSelectedChecklist(selected);

      if (selected) {
        fetchUnitChecklistItems(selected.id);
      }
    },
    [unitChecklists],
  );

  const handleOnCloseModal = () => {
    setShowModalForm(false);
    getChecklistsUnidade();
    setShowModalFormViewOnly(false);
  };

  const handleOnChecklistItensModalClose = () => {
    setShowModalFormChecklistItem(false);
    setSelectedChecklistItem(undefined);
    if (selectedChecklist) {
      fetchUnitChecklistItems(selectedChecklist.id);
    }
  };

  const handleDeleteChecklist = (id: number) => {
    Swal.fire({
      ...swalOptions,
    }).then(async (result) => {
      if (result.value) {
        try {
          await api.delete(`${CHECKLIST_UNIDADE_BASE_URL}${id}`);
          setSelectedChecklist(undefined);
          getChecklistsUnidade();
          setUnitChecklistItems([]);
          toast.success('Checklist excluído com sucesso!');
        } catch (error) {
          toast.error('Erro ao excluir pergunta!');
        }
      }
    });
  };

  const handlerDuplicarChecklist = useCallback((questionnaireId: number) => {
    api
      .post(`${CHECKLIST_UNIDADE_BASE_URL}duplicar/${questionnaireId}`)
      .then((response) => {
        getChecklistsUnidade();
        setUnitChecklists([]);
        toast.success('Checklist duplicada com sucesso!');
      })
      .catch((error: any) => {
        toast.error('Error ao tentar duplicar Checklist!');
      });
  }, []);

  const handleDeleteChecklistItem = useCallback(
    (selectedChecklistItemId: number) => {
      Swal.fire({
        ...swalOptions,
      }).then(async (result) => {
        if (result.value) {
          try {
            await api.delete(`${CHECKLIST_UNIDADE_BASE_URL}item/${selectedChecklistItemId}`);
            fetchUnitChecklistItems(selectedChecklist?.id || 0);
            toast.success('Item excluído com sucesso!');
          } catch (error) {
            toast.error('Erro ao excluir Item!');
          }
        }
      });
    },
    [selectedChecklist],
  );

  const handleDisplayTitle = (item: any) => {
    if (item.liberado) {
      return (
        <span>
          <strong>{item.titulo}</strong>
          <FaCheckCircle
            size={15}
            color="#4dbd74"
            style={{ marginLeft: '5px', marginTop: '-5px' }}
          />
        </span>
      );
    }
    return <span>{item.titulo}</span>;
  };

  const handleOnDragEndChecklists = useCallback(
    async (resultIndex: number, resultDestination: number) => {
      setUnitChecklists((old) => {
        const [reorderedItem] = old.splice(resultIndex, 1);
        old.splice(resultDestination, 0, reorderedItem);
        return old;
      });
      const ordenacaoChecklists = unitChecklists
        .map((p, index) => ({
          id: p.id,
          ordem: index,
        }))
        .concat([]);
      try {
        await api.put(`${CHECKLIST_UNIDADE_BASE_URL}ordenar`, ordenacaoChecklists);
      } catch {
        toast.error('Erro ao ordernar checklists');
      }
    },
    [unitChecklists],
  );

  const handleQuestionarioDragEnd = useCallback(
    async (resultIndex: number, resultDestination: number) => {
      setUnitChecklistItems((old) => {
        const [reorderedItem] = old.splice(resultIndex, 1);
        old.splice(resultDestination, 0, reorderedItem);
        return old;
      });
      const ordenacaoChecklistItems = unitChecklistItems
        .map((p, index) => ({
          id: p.id,
          ordem: index,
        }))
        .concat([]);
      try {
        await api.put(`checklistunidade/ordenar/${unitChecklistItems[0].checklistUnidadeId}`, ordenacaoChecklistItems);
      } catch {
        toast.error('Erro ao ordernar itens do checklists');
      }
    },
    [unitChecklistItems],
  );

  const handleEditClick = (itemId: number) => {
    const selected = unitChecklists.find((checklist) => checklist.id === itemId);
    if (selected) {
      selected.unidadesIds = selected?.unidadesEmpresa.map((item: any) => ({
        label: item.nome,
        value: item.id,
      }));
      selected.cargosIds = selected?.cargos.map((item: any) => ({
        label: item.nome,
        value: item.id,
      }));
    }
    setSelectedChecklist(selected);
    setShowModalForm(true);
  };

  const handlePreviewClick = (itemId: number) => {
    const selected = unitChecklists.find((checklist) => checklist.id === itemId);
    if (selected) {
      selected.unidadesIds = selected?.unidadesEmpresa.map((item: any) => ({
        label: item.nome,
        value: item.id,
      }));
      selected.cargosIds = selected?.cargos.map((item: any) => ({
        label: item.nome,
        value: item.id,
      }));
    }
    setSelectedChecklist(selected);
    setShowModalFormViewOnly(true);
  };

  const handleDesativarChecklist = useCallback(async () => {
    try {
      Swal.fire({
        title: 'Você quer realmente desativar?',
        icon: 'warning',
        input: 'text',
        inputLabel: 'Gostaria de informar o motivo da desativação?',
      }).then((result) => {
        const data = {
          data: {
            motivoDesativacao: result.value,
          },
        };

        api.delete(`/checklistunidade/${selectedChecklist?.id}/desativar`, data).then(() => {
          getChecklistsUnidade();
          setSelectedChecklist(undefined);
          toast.success('Questionário desativado!');
        });
      });
    } catch (error: any) {
      if (error.response.data) {
        toast.error(error.response.data.errors.join(','));
      } else {
        toast.error('Erro ao desativar questionário!');
      }
    }
  }, [selectedChecklist]);

  const handleDisableScheduler = () => {
    try {
      api.delete(`/checklistunidade/${selectedChecklist?.id}/cancelar-lancamento`).then(() => {
        getChecklistsUnidade();
        setSelectedChecklist(undefined);
        toast.success('Agendamento cancelado!');
      });
    } catch (error: any) {
      toast.error('Erro ao cancelar checklist!');
      if (error.response.data) {
        toast.error(error.response.data.errors.join(','));
      } else {
        toast.error('Erro ao cancelar checklist!');
      }
    }
  };

  return (
    <>
      <ModalFormChecklist
        showModal={showModalForm || showModalFormViewOnly}
        onCloseModal={handleOnCloseModal}
        checklist={selectedChecklist}
        unidadeEmpresaId={parseInt(unidadeId, 10)}
        viewOnly={showModalFormViewOnly}
      />

      {showModalFormChecklistItem && (
        <ModalFormChecklistItens
          showModal={showModalFormChecklistItem}
          onCloseModal={handleOnChecklistItensModalClose}
          checklist={selectedChecklist}
          checklistItem={selectedChecklistItem}
        />
      )}

      <ModalFormAgendamento
        onClose={() => {
          getChecklistsUnidade();
          setSelectedChecklist(undefined);
          setIsOpenModalFormAgendamento(false);
        }}
        isOpen={isOpenModalFormAgendamento}
        id={selectedChecklist?.id}
      />

      <Header className="mt-3">
        <Row>
          <Col md={6} className="col-spacing d-flex">
            <SiCircle size={35} />
            <div style={{ flex: 1 }}>
              <h5>Checklists da Unidade</h5>
              <p>Vamos colocar a mão na massa!</p>
            </div>
          </Col>
          {selectedChecklist?.id
            && (
              <>
                {!selectedChecklist?.liberado && (
                  <>
                    <Col md={6} xs={6} className="d-flex align-items-center">
                      {selectedChecklist?.agendado ? (
                        <Button
                          style={{ backgroundColor: '#e22c2c' }}
                          type="button"
                          color="#fff"
                          className="mt-2"
                          onClick={handleDisableScheduler}
                        >
                          Cancelar lançamento
                        </Button>
                      )
                        : (
                          <Button
                            style={{ backgroundColor: '#43af56' }}
                            type="button"
                            color="#fff"
                            className="mt-2"
                            onClick={() => setIsOpenModalFormAgendamento(true)}
                          >
                            Programar lançamento
                          </Button>
                        )
                      }
                    </Col>
                  </>
                )}
                {selectedChecklist?.liberado && (
                  <Col md={6} xs={6} className="d-flex align-items-center">
                    <Button
                      type="button"
                      color="#fff"
                      className="mt-2"
                      onClick={handleDesativarChecklist}
                    >
                      Desativar Checklist
                    </Button>
                  </Col>
                )}
              </>
            )}
        </Row>
      </Header>
      <Row>
        <Col md={6}>
          <BoxItemGame
            header="Checklists"
            icon={SiCircle}
            items={unitChecklists}
            onShowFormEdit={(itemId: number) => handleEditClick(itemId)}
            onShowFormModal={() => {
              setShowModalForm(true);
              setSelectedChecklist(undefined);
            }}
            onDeleteItem={(itemId: number) => {
              const selected = unitChecklists.find((checklist) => checklist.id === itemId);
              if (selected) {
                setSelectedChecklist(selected);
                handleDeleteChecklist(selected.id);
              }
            }}
            onPreview={(itemId: number) => handlePreviewClick(itemId)}
            onDuplicateWithId={handlerDuplicarChecklist}
            onSelecetItem={(itemId: number) => handleOnChecklistSelect(itemId)}
            selectedId={selectedChecklist?.id}
            displayTitle={handleDisplayTitle}
            onDragEnd={handleOnDragEndChecklists}
            superiorSelecionado
          />
        </Col>
        <Col md={6}>
          {selectedChecklist ? (
            <BoxItemGame
              header={`Itens de Checagem do ${selectedChecklist?.titulo}`}
              icon={SiCircle}
              items={unitChecklistItems || []}
              onShowFormEdit={
                !(selectedChecklist?.liberado || selectedChecklist?.agendado)
                  ? (itemId: number) => {
                    if (selectedChecklist?.itens) {
                      const selected = unitChecklistItems?.find(
                        (checklistItem: IUnitChecklistItem) => checklistItem.id === itemId,
                      );
                      setSelectedChecklistItem(selected);
                      setShowModalFormChecklistItem(true);
                    }
                  }
                  : undefined
              }
              onShowFormModal={() => {
                setShowModalFormChecklistItem(true);
              }}
              onDeleteItem={handleDeleteChecklistItem}
              superiorSelecionado={!!selectedChecklist}
              disabledButtonAdd={!selectedChecklist}
              onDragEnd={handleQuestionarioDragEnd}
              displayTitle={handleDisplayTitle}
            />
          ) : (
            <Row>
              <Col>
                <p className="theme-color text-center pt-5">
                  Selecione um Checklist para incluir ou alterar itens.
                </p>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
};
