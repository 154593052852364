/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

// interface HtmlEditorProps {}

export interface HtmlEditorProps {
  handleOnChange: (content: string) => void,
  content?: string,
  hideImage?: boolean,
}

export const HtmlEditor = ({ handleOnChange, content, hideImage = false }: HtmlEditorProps) => {
  const editorRef: any = useRef(null);

  const getInit = () => {
    let initOptions = {
      automatic_uploads: false,
      menubar: false,
      plugins: [
        'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen link template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount textpattern noneditable help charmap quickbars emoticons',
      ],
      toolbar:
        'undo redo | formatselect | '
        + 'bold italic backcolor forecolor | alignleft aligncenter '
        + 'alignright alignjustify | bullist numlist outdent indent | '
        + 'removeformat | help',
      content_style:
        'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
    };

    if (hideImage) {
      initOptions = Object.assign(initOptions, { quickbars_insert_toolbar: 'quicktable' });
    }

    return initOptions;
  };

  return (
    <>
      {/* // eslint-disable-next-line @typescript-eslint/no-unused-vars */}
      <Editor
        apiKey={process.env.REACT_APP_TINY_MCE_TOKEN}
        onInit={(evt, editor) => {
          editorRef.current = editor;
        }}
        onChange={() => {
          handleOnChange(editorRef.current.getContent());
        }
        }
        initialValue={content || ''}
        init={getInit()}
      />
    </>
  );
};
