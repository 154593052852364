/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Formik } from 'formik';
import { useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BiTask } from 'react-icons/bi';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import Switch from 'react-switch';
import { Button } from '../../../../components/Button';
import theme from '../../../../styles/theme';
import { Input } from '../../../../components/Input';
import { ModalComponent } from '../../../../components/Modal';
import api from '../../../../services/api';
import { IUnitChecklist } from '../../../../interfaces/business-units/checklists/IUnitChecklist';
import { IUnitChecklistItem } from '../../../../interfaces/business-units/checklists/item/IUnitChecklistItem';
import { LabelStyled } from '../../components/ModalImport/styles';
import { CloseCircleIcon, ImagePreview } from '../../../Trilhas/components/ModalFormQuizV2/styles';

interface ModalFormProps {
  onCloseModal: () => void;
  showModal: boolean;
  checklist?: IUnitChecklist;
  checklistItem?: IUnitChecklistItem;
}

export const ModalFormChecklistItens = ({
  onCloseModal,
  showModal,
  checklist,
  checklistItem,
}: ModalFormProps) => {
  const [loading, setLoading] = useState(false);
  const [includeImage, setIncludeImage] = useState<boolean>(!!checklistItem?.urlImagemConformidade ?? false);
  const [requiredPhoto, setRequiredPhoto] = useState<boolean>(checklistItem?.fotoObrigatoria ?? false);
  const [base64Image, setBase64Image] = useState<string | undefined>(checklistItem?.urlImagemConformidade ?? false);
  const [notApplicable, setNotApplicable] = useState<boolean>(checklistItem?.permiteRespostaNA ?? false);

  const initialValues: any = {
    titulo: '',
    conformidade: '',
    imagemConformidade: '',
    fotoObrigatoria: '',
    permiteRespostaNA: false,
  };

  const PERSIST_CHECKLIST_URL = '/checklistunidade/item';

  const validationSchema = yup.object().shape({
    titulo: yup.string().required('Campo obrigatório'),
    conformidade: yup.string(),
  });

  const handleSubmit = useCallback(
    async (dataForm: any) => {
      const requestData = {
        ...dataForm,
        imagemConformidade: base64Image,
        checklistUnidadeId: checklist?.id,
        fotoObrigatoria: requiredPhoto,
        permiteRespostaNA: notApplicable,
      };

      try {
        const sendRequest = checklistItem ? api.put : api.post;
        await sendRequest(PERSIST_CHECKLIST_URL, requestData).then(() => {
          toast.success('Operação realizada com sucesso');
        });
        onCloseModal();
      } catch (error: any) {
        if (error.response.data.message) {
          toast.error(error.response.data.message);
          return;
        }
        toast.error('Erro ao salvar Item de Checagem');
      } finally {
        setLoading(false);
      }
    },
    [onCloseModal, base64Image, requiredPhoto, notApplicable],
  );

  const handleInputChange = (event: any) => {
    const { files } = event.target;

    if (!files || files.length < 1) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = (e: any) => {
      setBase64Image(e.target.result);
    };
  };

  return (
    <>
      <ModalComponent
        onClose={() => onCloseModal()}
        modalVisible={showModal}
        title={checklistItem ? 'Alterar Item de Checagem' : 'Incluir Item de Checagem'}
        icon={BiTask}
        size="lg"
      >
        <Formik
          initialValues={checklistItem || initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <Row>
              <Col xs={12} md={12}>
                <Input type="text" name="titulo" label="Item a checar" isRequerid />
                <Input type="text" name="conformidade" label="Explique aqui como deve estar:" />
                <div className="d-flex flex-column mb-3">
                  <strong style={{ fontSize: '0.8rem', color: '#333' }} className="mb-2">
                    Incluir Imagem?
                  </strong>
                  <Switch
                    onChange={() => setIncludeImage((old) => !old)}
                    checked={includeImage}
                    height={22}
                    width={53}
                  />
                </div>
                {includeImage && (
                  <input type="file" name="imagemConformidade" onChange={handleInputChange} />
                )}
                {base64Image && (
                  <>
                    <LabelStyled>Confira aqui:</LabelStyled>
                    <div className="d-flex justify-content-center">
                      <ImagePreview src={base64Image} alt="Pre-visualizacao da imagem" />
                      <CloseCircleIcon onClick={() => setBase64Image(undefined)}>X</CloseCircleIcon>
                    </div>
                  </>
                )}
                <div className="d-flex flex-column mb-3">
                  <strong style={{ fontSize: '0.8rem', color: '#333' }} className="mb-2">
                    Foto obrigatória para checagem?
                  </strong>
                  <Switch
                    onChange={() => setRequiredPhoto((oldValue) => !oldValue)}
                    checked={requiredPhoto}
                    height={22}
                    width={53}
                  />
                </div>
                <div className="d-flex flex-column mb-3">
                  <strong style={{ fontSize: '0.8rem', color: '#333' }} className="mb-2">
                    Permite botão não se aplica?
                  </strong>
                  <Switch
                    onChange={() => setNotApplicable((oldValue) => !oldValue)}
                    checked={notApplicable}
                    height={22}
                    width={53}
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs={{ span: 6, offset: 3 }} md={{ span: 4, offset: 4 }}>
                <Button
                  background={theme.colors.main}
                  color="#fff"
                  className="text-uppercase"
                  type="submit"
                  loading={loading}
                >
                  Salvar
                </Button>
              </Col>
            </Row>
          </Form>
        </Formik>
      </ModalComponent>
    </>
  );
};
