import { BsCircle } from 'react-icons/bs';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ModalComponent } from '../../../../../components/Modal';
import { HtmlEditor } from '../../../../Use-Terms/components/Html-Editor';
import { Button } from '../../../../../components/Button';
import { LabelConteudo } from './styles';

interface ModalFormProps {
  originalContent: string;
  isShowModal: boolean;
  onCloseModal: (newContent: string) => void;
}

export const ModalFormTarefaHTMLEditor = ({
  onCloseModal,
  isShowModal,
  originalContent }: ModalFormProps) => {
  const [content, setContent] = useState<string>('');

  const handleOnHtmlEditorChange = (contentText: string) => {
    setContent(contentText);
  };

  const handleButtonSave = () => {
    onCloseModal(content);
  };

  return (
    <ModalComponent
      onClose={() => { onCloseModal(originalContent); }}
      modalVisible={isShowModal}
      icon={BsCircle}
      title="Incluir texto de aprendizado"
      size="lg"
    >
      <div>
        <Row className="mt-2">
          <Col xs={12}>
            <LabelConteudo>
              <h4>Conteúdo de aprendizado </h4>
            </LabelConteudo>
            <HtmlEditor
              handleOnChange={handleOnHtmlEditorChange}
              content={originalContent}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col xs={12}>
            <Button color="#fff" onClick={handleButtonSave}>Salvar</Button>
          </Col>
        </Row>
      </div>

    </ModalComponent>
  );
};
