import { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { AiOutlineStar } from 'react-icons/ai';
import * as yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { Button } from '../../../../components/Button';
import { Input } from '../../../../components/Input';
import { ModalComponent } from '../../../../components/Modal';
import api from '../../../../services/api';
import theme from '../../../../styles/theme';
import { ICargo } from '../../../../interfaces/ICargo';
import { ReactSelect } from '../../../../components/ReactSelect';
import { CheckAndUncheckBtn } from '../../../Users/styles';

interface ModalFormPerfilProps {
  onSetPerfil: (data: ICargo, update?: boolean) => void;
  onCloseModal: () => void;
  perfil?: Perfis;
  showModal: boolean;
}

interface Funcionalidades {
  funcionalidadeId?: number;
  nome?: string;
  area?: string;
}

interface Perfis {
  id?: number;
  nome?: string,
  descricao?: string,
  funcionalidades?: Funcionalidades[]
}

export const ModalFormPerfil = ({
  onSetPerfil,
  perfil,
  onCloseModal,
  showModal,
}: ModalFormPerfilProps) => {
  const [loading, setLoading] = useState(false);
  const [funcionalidades, setFuncionalidades] = useState<any[]>([]);
  const [editPerfil, setEditPerfil] = useState<Perfis>();

  useEffect(() => {
    api
      .get('perfis/funcionalidades')
      .then(({ data }) => {
        const funcionalidadesFormatadas = data?.data?.map((func: Funcionalidades) => ({
          label: `${func?.area} - ${func?.nome}`,
          value: func?.funcionalidadeId,
        }));
        setFuncionalidades(funcionalidadesFormatadas);
      })
      .catch((err: any) => toast.error(err?.response?.message || 'Erro ao buscar perfis'));
  }, [showModal]);

  useEffect(() => {
    if (perfil) {
      api
        .get(`perfis/${perfil?.id}`)
        .then(({ data }) => {
          const formattedFuncionalidades = data?.data?.funcionalidades.map((item: any) => ({
            ...item,
            nome: `${item.area} - ${item.nome}`,
          }));

          const newObject = {
            ...data.data,
            funcionalidades: formattedFuncionalidades,
          };
          setEditPerfil(newObject);
        })
        .catch((err: any) => toast.error(err?.response?.message || 'Erro ao buscar perfis'));
    }
  }, [perfil]);

  const handleSubmit = useCallback(
    async (dataForm: any) => {
      const dataFormatted = {
        ...dataForm,
        funcionalidadesIds: dataForm.funcionalidades.map((func: any) => func.value),
      };

      try {
        setLoading(true);
        if (perfil) {
          const { data } = await api.put(`/perfis/${perfil.id}`, {
            id: perfil.id,
            ...dataFormatted,
          });
          onSetPerfil(data.data, true);
        } else {
          const { data } = await api.post('/perfis', {
            ...dataFormatted,
          });
          onSetPerfil(data.data);
        }

        onCloseModal();
        toast.success('Perfil foi adicionado com sucesso');
      } catch (err: any) {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
          return;
        }
        toast.error('Erro ao adicionar o novo perfil');
      } finally {
        setLoading(false);
      }
    },
    [onCloseModal, onSetPerfil, perfil],
  );

  const schema = yup.object({
    nome: yup.string().required('O nome do perfil é obrigatório').min(2, 'O nome deve ter pelo menos 2 caracteres').max(100, 'O nome não pode exceder 100 caracteres'),
    descricao: yup.string().required('A descrição é obrigatória').min(2, 'A descrição deve ter pelo menos 2 caracteres').max(255, 'A descrição não pode exceder 255 caracteres'),
  });

  const dadosIniciais = {
    nome: editPerfil?.nome,
    descricao: editPerfil?.descricao,
    funcionalidades: editPerfil?.funcionalidades?.map((func: Funcionalidades) => ({
      label: func?.nome,
      value: func?.funcionalidadeId,
    })) ?? [],
  };

  const handleCloseModal = () => {
    setFuncionalidades([]);
    setEditPerfil(undefined);
    onCloseModal();
  };

  return (
    <ModalComponent
      onClose={handleCloseModal}
      modalVisible={showModal}
      title={editPerfil ? 'Editar Perfil de Acesso' : 'Adicionar Perfil de Acesso'}
      icon={AiOutlineStar}
    >
      <></>
      {editPerfil
        ? <h6>Digite o nome do perfil que deseja alterar</h6>
        : <h6>Digite o nome do perfil que deseja adicionar.</h6>}
      <Formik
        initialValues={dadosIniciais}
        enableReinitialize
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue }) => (
          <Form>
            <Input type="text" name="nome" label="Nome do Perfil:" isRequerid />
            <Input type="text" name="descricao" label="Descrição:" isRequerid />
            <Field
              name="funcionalidades"
              isRequerid
              label="Nível de Acesso"
              isMulti
              component={ReactSelect}
              options={funcionalidades}
            />
            <CheckAndUncheckBtn
              onClick={() => setFieldValue('funcionalidades', funcionalidades, false)}
            >
              Marcar Todos
            </CheckAndUncheckBtn>
            <CheckAndUncheckBtn href="#" onClick={() => setFieldValue('funcionalidades', [], false)}>
              Desmarcar Todos
            </CheckAndUncheckBtn>
            <Row className="mt-4">
              <Col md={{ span: 4, offset: 4 }}>
                <Button
                  background={theme.colors.main}
                  color="#fff"
                  className="text-uppercase"
                  loading={loading}
                  type="submit"
                >
                  Adicionar
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </ModalComponent>
  );
};
