/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BiTask } from 'react-icons/bi';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { IoAdd, IoRemove } from 'react-icons/io5';
import Select from 'react-select';
import Switch from 'react-switch';
import { Button } from '../../../../components/Button';
import theme from '../../../../styles/theme';
import { Input } from '../../../../components/Input';
import { ModalComponent } from '../../../../components/Modal';
import { SpanAlternative } from '../ModalFormQuiz/styles';
import { IQuiz } from '../../../../interfaces/IQuiz';
import { LabelStyled } from '../../../Users/ModalImport/styles';
import { CloseCircleIcon, ImagePreview } from './styles';
import { HtmlEditor } from '../../../Use-Terms/components/Html-Editor';

interface ModalFormProps {
  onCloseModal: () => void;
  showModal: boolean;
  onSetQuizzes: (data: IQuiz, update?: boolean) => void;
  quiz?: IQuiz;
}

const TYPES_NIVEL_RELEVANCIA = [
  {
    value: 1,
    label: 'Baixa - 10 pts',
  },
  {
    value: 2,
    label: 'Média - 20 pts',
  },
  {
    value: 3,
    label: 'Alta - 30 pts',
  },
  {
    value: 4,
    label: 'Indiferente - 0 pts',
  },
];

const schema = yup.object({
  // pergunta: yup.string().required('Adicione a pergunta'),
  alternativas: yup
    .array()
    .of(
      yup.object().shape({
        // descricao: yup.string().required('Campo obrigatório'),
      }),
    ),
});

const initialValues: IQuiz = {
  pergunta: '',
  tarefaId: undefined,
  nivelRelevanciaId: 1,
  alternativas: [
    {
      id: 0,
      descricao: '',
      valor: false,
    },
    {
      id: 0,
      descricao: '',
    },
    {
      id: 0,
      descricao: '',
    },
  ],
};

export const ModalFormQuiz = ({ onCloseModal, showModal, onSetQuizzes, quiz }: ModalFormProps) => {
  const [check, setCheck] = useState('');
  const [includeImage, setIncludeImage] = useState<boolean>(false);
  const [base64Image, setBase64Image] = useState<string>();
  const [question, setQuestion] = useState('');

  useEffect(() => {
    if (quiz) {
      const findIndex = quiz.alternativas.findIndex((item) => item.valor);
      setCheck(String(findIndex));
      setIncludeImage(!!quiz?.imagem);
      setBase64Image(quiz?.imagem);
      setQuestion(quiz?.pergunta);
    }
    return () => {
      setCheck('');
      setIncludeImage(false);
    };
  }, [quiz]);

  useEffect(() => {
    if (!includeImage) {
      setBase64Image(undefined);
    }
  }, [includeImage]);

  const handleAddMoreAlternatives = useCallback((setValues: (values: any) => void) => {
    setValues((values: any) => {
      values.alternativas.push({
        id: 0,
        descricao: '',
      });

      return values;
    });
  }, []);

  const handleRemoveAlternatives = useCallback(
    (setValues: (values: any) => void, index: number) => {
      setValues((values: any) => {
        values.alternativas.splice(index, 1);
        return values;
      });
    },
    [],
  );

  const handleOnHtmlEditorChange = (contentText: string) => {
    setQuestion(contentText);
  };

  const handleSubmit = useCallback(
    async (dataForm: IQuiz) => {
      if (dataForm.alternativas.length < 2) {
        toast.error('Adicione ao menos 2 respostas para esta pergunta');
        return;
      }

      if (!check) {
        toast.error('Selecione a resposta certa para essa questão');
        return;
      }
      let isItemEmpty = false;
      dataForm.alternativas.forEach((item) => {
        if (!isItemEmpty) {
          if (!item.descricao) {
            isItemEmpty = true;
          }
        }
      });

      if (isItemEmpty) {
        toast.error('Adicione todas as respostas');
        return;
      }

      const generateKey = () => Math.floor(1000 + Math.random() * 9000);

      const rightAnswer: boolean = dataForm?.alternativas?.some((item) => item?.valor === true);

      if (!rightAnswer) {
        toast.error('Selecione a resposta certa para essa questão');
        return;
      }

      const dataFormatted: any = {
        ...dataForm,
        key: quiz?.id || quiz?.key || generateKey(),
        pergunta: question,
        imagem: includeImage ? base64Image : undefined,
        alternativas: dataForm.alternativas.map((item, index) => {
          isItemEmpty = !item.descricao;

          return {
            ...item,
            key: item.id !== 0 ? item.id : generateKey(),
            valor: index === +check,
          };
        }),
      };

      if (quiz) {
        onSetQuizzes(dataFormatted, true);
      } else {
        onSetQuizzes(dataFormatted);
      }

      setIncludeImage(false);
      setCheck('');
    },
    [check, quiz, base64Image, onSetQuizzes, question],
  );

  const SelectNivelDificuldade = ({ field, ...props }: any) => (
    <div style={{ marginTop: '0', paddingTop: '0.2rem' }}>
      <label style={{ marginBottom: '0.5rem' }} className="label">
        Nível de relevância da pergunta
      </label>
      <Select
        name={field.name}
        isDisabled={props.disabled}
        options={TYPES_NIVEL_RELEVANCIA}
        defaultValue={TYPES_NIVEL_RELEVANCIA[0]}
        value={TYPES_NIVEL_RELEVANCIA.find((element) => element.value === field.value)}
        onChange={(option: any) => {
          props.form.setFieldValue('nivelRelevanciaId', option?.value, false);
          // setNivelPerguntaId(option?.value);
        }}
      />
    </div>
  );

  const handleInputChange = (event: any) => {
    const { files } = event.target;

    if (!files || files.length < 1) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = (e: any) => {
      setBase64Image(e.target.result);
    };
  };

  return (
    <>
      <ModalComponent
        onClose={() => onCloseModal()}
        modalVisible={showModal}
        title="Incluir Pergunta"
        icon={BiTask}
        size="lg"
      >
        <Formik
          initialValues={quiz || initialValues}
          enableReinitialize
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          {({ errors, values, setFieldValue, setValues }) => (
            <Form>
              <Row>
                <Col xs={12}>
                  <Field
                    name="pergunta"
                    component={HtmlEditor}
                    handleOnChange={handleOnHtmlEditorChange}
                    content={quiz?.pergunta}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Field
                    name="nivelRelevanciaId"
                    placeholder="Doe"
                    component={SelectNivelDificuldade}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <div className="d-flex flex-column mb-3">
                    <strong style={{ fontSize: '0.8rem', color: '#333' }} className="mb-2">
                      Incluir imagem?
                    </strong>
                    <Switch
                      onChange={() => setIncludeImage((old) => !old)}
                      checked={includeImage}
                      height={22}
                      width={53}
                    />
                  </div>
                  {includeImage && (
                    <div className="d-flex flex-column mb-3">
                      <Col md={12} style={{ marginBottom: '5px' }}>
                        <Input
                          type="file"
                          name="imgFile"
                          label="Escolha sua imagem aqui"
                          onChange={handleInputChange}
                          style={{ color: 'white' }}
                        />
                      </Col>
                      {base64Image && (
                        <Col>
                          <LabelStyled>Confira aqui</LabelStyled>
                          <div className="d-flex justify-content-center">
                            <ImagePreview src={base64Image} alt="Pre-visualizacao da imagem" />
                            <CloseCircleIcon onClick={() => setBase64Image(undefined)}>
                              X
                            </CloseCircleIcon>
                          </div>
                        </Col>
                      )}
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="mt-2">
                  <label htmlFor="qtdeAlternativas" className="styled-label">
                    Alternativas
                  </label>

                  <FieldArray name="alternativas">
                    {() => values.alternativas.map((item: any, idx: any) => (
                      <Row style={{ marginLeft: 0, marginBottom: '0.5rem' }} key={idx}>
                        <Col
                          xs={1}
                          className="rounded-gray-bg"
                          style={{ paddingLeft: '0', paddingRight: '0' }}
                        >
                          <SpanAlternative
                            type="button"
                            check={check === String(idx)}
                            onClick={() => {
                              setCheck(String(idx));
                              setFieldValue(`alternativas.${idx}.valor`, true);
                            }}
                            className={`border-0 ${check !== String(idx) ? 'text-secondary' : ' '
                              // eslint-disable-next-line indent
                              } rounded d-flex align-items-center justify-content-center`}
                            style={{ height: '100%' }}
                          >
                            {idx + 1}
                          </SpanAlternative>
                        </Col>

                        <Col xs={10}>
                          <Field
                            name={`alternativas.${idx}.descricao`}
                            type="text"
                            className="form-control"
                          />
                          <ErrorMessage
                            name={`alternativas.${idx}.descricao`}
                            component="div"
                            className="invalid-feedback"
                          />
                        </Col>

                        <Col xs={1}>
                          <Button
                            background={theme.colors.main}
                            color="#fff"
                            onClick={() => handleRemoveAlternatives(setValues, idx)}
                            type="button"
                          >
                            <IoRemove size={24} />
                          </Button>
                        </Col>
                      </Row>
                    ))
                    }
                  </FieldArray>

                  <Row>
                    <Col xs={{ span: 6, offset: 3 }} md={{ span: 4, offset: 4 }}>
                      <Button
                        background={theme.colors.main}
                        color="#fff"
                        onClick={() => handleAddMoreAlternatives(setValues)}
                        type="button"
                      >
                        <IoAdd size={24} />
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="mt-4">
                <Col xs={12}>
                  <Button
                    background={theme.colors.main}
                    color="#fff"
                    className="text-uppercase"
                    type="submit"
                  >
                    Salvar pergunta
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </ModalComponent>
    </>
  );
};
