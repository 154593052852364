/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Field, Form, Formik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BiTask } from 'react-icons/bi';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import Switch from 'react-switch';
import { Button } from '../../../../components/Button';
import theme from '../../../../styles/theme';
import { Input } from '../../../../components/Input';
import { ModalComponent } from '../../../../components/Modal';
import api from '../../../../services/api';
import { IUnitChecklist } from '../../../../interfaces/business-units/checklists/IUnitChecklist';
import { useAuth } from '../../../../hooks/auth';
import { ISelect, ISelectWNumber } from '../../../../interfaces/ISelect';
import { DAYS_OF_WEEK, OPTIONS_TIPOS_RECORRENCIA } from '../../../../utils/shared-functions';
import { SimpleSelect } from '../../../../components/SimpleSelect';
import { ReactSelect } from '../../../../components/ReactSelect';
import { ControlledReactSelect } from '../../../../components/ReactSelect/controlled-select';
import { CheckAndUncheckBtn } from '../../../Users/styles';
import { CheckboxCircle } from '../../../../components/CheckboxCircle';

const GET_AUDITORS_URL = 'checklistunidade/auditores';

interface ModalFormProps {
  checklist?: IUnitChecklist;
  onCloseModal: () => void;
  showModal: boolean;
  unidadeEmpresaId: number;
  viewOnly: boolean;
}

export const ModalFormChecklist = ({
  onCloseModal,
  showModal,
  checklist,
  unidadeEmpresaId,
  viewOnly,
}: ModalFormProps) => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isRecorrente, setIsRecorrente] = useState<boolean>(true);
  const [isIncluirVideo, setIsIncluirVideo] = useState<boolean>(false);
  const [isIncluirArquivoPdf, setIsIncluirArquivoPdf] = useState<boolean>(false);
  const [isDoubleChecking, setIsDoubleChecking] = useState<boolean>(false);
  const [isPermitidoImagemDaBiblioteca, setIsPermitidoImagemDaBiblioteca] = useState<boolean>(true);
  const [dataInicioRecorr, setDataInicioRecorr] = useState<string>('2023-08-01');
  const [auditors, setAuditors] = useState<ISelect[]>([]);
  const [periodicidadeRecorrencia, setPeriodicidadeRecorrencia]
    = useState<ISelectWNumber>(OPTIONS_TIPOS_RECORRENCIA[0]);
  const [daysOfWeek, setDaysOfWeek] = useState<ISelectWNumber[]>([]);
  const [currentAuditor, setCurrentAuditor]
    = useState<any[] | undefined>([]);
  const [unidadesEmpresa, setUnidadeEmpresa]
    = useState<any>();
  const [cargos, setCargos]
    = useState<any>();

  useEffect(() => {
    if (checklist) {
      const selectedOption = OPTIONS_TIPOS_RECORRENCIA.find(
        (option) => option.value
          === checklist.checklistUnidadeConfiguracaoRecorrencia[0]?.periodicidadeRecorrencia,
      );

      const diasSemanaRecorrencia = checklist
        .checklistUnidadeConfiguracaoRecorrencia[0]?.diasSemana;

      // eslint-disable-next-line max-len
      const auditorsData = checklist?.auditores?.map((item: any) => ({
        label: item.nome,
        value: item.id,
      }));
      setCurrentAuditor(auditorsData);

      setPeriodicidadeRecorrencia(selectedOption || OPTIONS_TIPOS_RECORRENCIA[1]);

      if (diasSemanaRecorrencia) {
        const days = DAYS_OF_WEEK.filter(
          (day) => diasSemanaRecorrencia.includes(day.value),
        );
        setDaysOfWeek(days);
      }

      const dateOriginal = checklist.dataInicioRecorrencia;
      if (dateOriginal) {
        const dateObject = new Date(dateOriginal);
        const formattedDate = dateObject.toISOString().split('T')[0];
        setDataInicioRecorr(formattedDate);
      }

      setIsRecorrente(checklist.isRecorrente);
      checklist?.auditores?.length
      && checklist?.auditores?.length > 0 ? setIsDoubleChecking(true) : setIsDoubleChecking(false);
    } else {
      setPeriodicidadeRecorrencia(OPTIONS_TIPOS_RECORRENCIA[0]);
    }

    if (typeof checklist?.isPermitidoImagemDaBiblioteca !== 'undefined') {
      setIsPermitidoImagemDaBiblioteca(!!checklist.isPermitidoImagemDaBiblioteca);
    }

    setIsIncluirVideo(!!checklist?.urlDoVideo);
    setIsIncluirArquivoPdf(!!checklist?.urlDoArquivo);
  }, [checklist]);

  useEffect(() => {
    api
      .get(GET_AUDITORS_URL)
      .then(({ data }) => {
        const auditorsData = data.data.map((item: { usuarioId: number; nome: string }) => ({
          label: `${item.usuarioId} - ${item.nome}`,
          value: item.usuarioId,
        }));

        setAuditors(auditorsData);
      })
      .catch(() => {
        toast.error('Erro ao buscar lista de Auditores');
      });
  }, []);

  const fetchUnidadeEmpresa = () => {
    api
      .get('unidade-empresa')
      .then(({ data }) => {
        setUnidadeEmpresa(
          data.data.map((item: { id: number; nome: string; }) => ({
            label: item.nome,
            value: item.id,
          })),
        );
      })
      .catch(() => {
        toast.error('Erro ao buscar lista de Unidade de Empresa');
      });
  };

  const fetchCargos = () => {
    api
      .get('cargo')
      .then(({ data }) => {
        setCargos(
          data.data.map((item: { id: number; nome: string; }) => ({
            label: item.nome,
            value: item.id,
          })),
        );
      })
      .catch(() => {
        toast.error('Erro ao buscar lista de Unidade de Empresa');
      });
  };

  useEffect(() => {
    fetchCargos();
    fetchUnidadeEmpresa();
  }, []);

  const validationSchema = yup.object({
    titulo: yup.string().required('O título é obrigatório'),
    unidadesIds: yup.array().min(1, 'Ao menos uma Unidade deve ser selecionada').required('Ao menos uma Unidade deve ser selecionada'),
    periodicidadeRecorrencia: yup
      .mixed()
      .test('notZero', 'Escolha uma opção.', () => periodicidadeRecorrencia.value !== 0 || !isRecorrente),
  });

  const cleanState = () => {
    setDataInicioRecorr('2023-08-01');
  };

  const handleCloseModal = useCallback(() => {
    cleanState();
    onCloseModal();
  }, [onCloseModal]);

  const getIds = (item: any) => item.value;

  const handleSubmit = async (dataForm: any) => {
    const solveAuditors = currentAuditor?.map((item) => item.value);
    setLoading(true);
    const cargosIdsArray = dataForm.cargosIds?.map(getIds);
    const unidadesIds = dataForm.unidadesIds?.map(getIds);

    const basePayload = {
      titulo: dataForm.titulo,
      descricao: dataForm.descricao,
      urlDoVideo: dataForm.urlDoVideo,
      urlDoArquivo: dataForm.urlDoArquivo,
      unidadesEmpresaIds: unidadesIds,
      checkListUnidadePai: checklist?.checklistUnidadePaiId,
      isRecorrente: isRecorrente ?? false,
      diasSemanaRecorrencia: daysOfWeek.map((day) => day.value),
      duplaVerificacao: isDoubleChecking,
      auditorIds: solveAuditors,
      periodicidadeRecorrencia: periodicidadeRecorrencia?.value,
      dataInicioRecorrencia: dataInicioRecorr,
      checklistUnidadeCategoriaId: unidadeEmpresaId,
      cargosIds: cargosIdsArray,
      isPermitidoImagemDaBiblioteca: isPermitidoImagemDaBiblioteca ?? false,
    };

    try {
      if (checklist) {
        await api.put('/checklistunidade', {
          ...basePayload,
          id: checklist.id,
        }).then(() => {
          toast.success('Edição de Checklist realizada com sucesso');
          onCloseModal();
        });
      } else {
        await api.post('/checklistunidade', {
          ...basePayload,
          usuarioId: user.UsuarioId,
        }).then(() => {
          toast.success('Criação de Checklist realizada com sucesso');
          onCloseModal();
        });
      }
    } catch (error: any) {
      if (error.response.data.message) {
        toast.error(error.response.data.message);
        return;
      }
      toast.error('Erro ao salvar Checklist');
    } finally {
      setLoading(false);
      cleanState();
    }
  };

  const initialData = {
    titulo: '',
    unidadesIds: [],
    periodicidadeRecorrencia: OPTIONS_TIPOS_RECORRENCIA[0],
  };

  const handleChangeAuditors = (e: any) => {
    setCurrentAuditor(e);
  };

  const getLabel = (): string => {
    let result = 'Incluir Checklist';

    if (checklist) {
      result = viewOnly ? 'Visualizar Checklist - (desative o checklist caso queira editar)' : 'Alterar Checklist';
    }

    return result;
  };

  return (
    <>
      <ModalComponent
        onClose={() => handleCloseModal()}
        modalVisible={showModal}
        title={getLabel()}
        icon={BiTask}
        size="lg"
      >
        <Formik
          initialValues={checklist || initialData}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue }: any) => (
            <Form>
              <Row className="mb-2">
                <Col xs={12} md={12}>
                  <Input type="text" name="titulo" label="Título:" isRequerid />
                </Col>
              </Row>

              <Row className="mb-2">
                <Col xs={12} md={12}>
                  <Input type="text" name="descricao" label="Descrição:" />
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={12} className="d-flex flex-column mb-3">
                  <strong style={{ fontSize: '0.8rem', color: '#333', paddingLeft: '4px' }} className="mb-2">
                    Incluir vídeo
                  </strong>
                  <Switch
                    onChange={() => setIsIncluirVideo((old) => !old)}
                    checked={isIncluirVideo}
                    height={22}
                    width={53}
                  />
                  {isIncluirVideo
                    && (
                      <>
                        <Col md={12}>
                          <Row className="mb-2">
                            <Col xs={12} md={12} className="mb-2">
                              <Input type="text" name="urlDoVideo" label="URL do vídeo" />
                            </Col>
                          </Row>
                        </Col>
                      </>
                    )
                  }
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={12} className="d-flex flex-column mb-3">
                  <strong style={{ fontSize: '0.8rem', color: '#333', paddingLeft: '4px' }} className="mb-2">
                    Incluir arquivo PDF
                  </strong>
                  <Switch
                    onChange={() => setIsIncluirArquivoPdf((old) => !old)}
                    checked={isIncluirArquivoPdf}
                    height={22}
                    width={53}
                  />
                  {isIncluirArquivoPdf
                    && (
                      <>
                        <Col md={12}>
                          <Row className="mb-2">
                            <Col xs={12} md={12}>
                              <Input type="text" name="urlDoArquivo" label="URL do arquivo" />
                            </Col>
                          </Row>
                        </Col>
                      </>
                    )
                  }
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={12} className="d-flex flex-column mb-3">
                  <strong style={{ fontSize: '0.8rem', color: '#333', paddingLeft: '4px' }} className="mb-2">
                    Dupla verificação?
                  </strong>

                  <Switch
                    onChange={() => setIsDoubleChecking((old) => !old)}
                    checked={isDoubleChecking}
                    height={22}
                    width={53}
                  />

                  {isDoubleChecking
                    && (
                      <>
                        <Col md={12}>
                          <ControlledReactSelect
                            label="Auditores"
                            options={auditors}
                            value={currentAuditor}
                            isMulti
                            onChange={(e: any) => handleChangeAuditors(e)}
                          />
                        </Col>
                      </>
                    )
                  }
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={12} className="d-flex flex-column mb-3">
                  <strong
                    style={{ fontSize: '0.8rem', color: '#333', paddingLeft: '4px' }}
                    className="mb-2"
                  >
                    Permitir imagem da biblioteca?
                  </strong>

                  <Switch
                    onChange={() => setIsPermitidoImagemDaBiblioteca((old) => !old)}
                    checked={isPermitidoImagemDaBiblioteca}
                    height={22}
                    width={53}
                  />
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={12} className="d-flex flex-column mb-3">
                  <strong style={{ fontSize: '0.8rem', color: '#333', paddingLeft: '4px' }} className="mb-2">
                    É recorrente?
                  </strong>

                  <Switch
                    onChange={() => setIsRecorrente((old) => !old)}
                    checked={isRecorrente}
                    height={22}
                    width={53}
                  />

                  {isRecorrente
                    && (
                      <>
                        <Col xs={12}>
                          <SimpleSelect
                            label="Repetir"
                            name="periodicidadeRecorrencia"
                            options={OPTIONS_TIPOS_RECORRENCIA}
                            defaultValue={OPTIONS_TIPOS_RECORRENCIA[1]}
                            value={periodicidadeRecorrencia?.value}
                            onChange={(event: any) => {
                              const s = OPTIONS_TIPOS_RECORRENCIA?.find(
                                (option: any) => option.value === Number(event.target.value),
                              );
                              if (s) {
                                setPeriodicidadeRecorrencia(s);
                              }
                              // if (handleStatusCursoChange) {
                              //   handleStatusCursoChange(event.target.value);
                              // }
                            }}
                          />
                        </Col>
                          {periodicidadeRecorrencia === OPTIONS_TIPOS_RECORRENCIA[1]
                            && (
                            <Col>
                              <Col style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                                {DAYS_OF_WEEK.map((day) => (
                                  <CheckboxCircle
                                    label={day.label.charAt(0)}
                                    checked={daysOfWeek.includes(day)}
                                    onChange={(e) => {
                                      console.log('e', e);
                                      if (e.target.checked) {
                                        console.log('day', day);
                                        setDaysOfWeek([...daysOfWeek, day]);
                                      } else {
                                        setDaysOfWeek(daysOfWeek.filter((item) => item !== day));
                                      }
                                    }}
                                  />
                                ))}
                              </Col>
                              <Col style={{ marginTop: '5px', fontSize: '0.8rem' }}>
                                <span><b>Recorrência nos dias: </b></span>
                                {daysOfWeek
                                  .sort((a, b) => a.value - b.value)
                                  .map((day) => day.label).join(', ') || 'Nenhum dia selecionado'}
                              </Col>
                            </Col>
                            )}

                        <Col md={6}>
                          <Input
                            type="date"
                            name="dataInicioRecorrencia"
                            label="Data Inicio Repetição"
                            value={dataInicioRecorr}
                            onChange={(e: any) => setDataInicioRecorr(e.target.value)}
                          />
                        </Col>
                      </>
                    )
                  }
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs={12} md={12}>
                  <Col md={6}>
                    <Field
                      name="unidadesIds"
                      isRequerid
                      label="Unidades (pode ser mais de 1)"
                      isMulti
                      component={ReactSelect}
                      options={unidadesEmpresa}
                    />
                    <CheckAndUncheckBtn
                      href="#"
                      onClick={() => setFieldValue('unidadesIds', unidadesEmpresa, false)}
                    >
                      Marcar Todos
                    </CheckAndUncheckBtn>
                    <CheckAndUncheckBtn
                      href="#"
                      onClick={() => setFieldValue('unidadesIds', [], false)}
                    >
                      Desmarcar Todos
                    </CheckAndUncheckBtn>
                  </Col>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs={12} md={12}>
                  <Col md={6}>
                    <Field
                      name="cargosIds"
                      label="Cargos (pode ser mais de 1)"
                      isMulti
                      component={ReactSelect}
                      options={cargos}
                    />
                    <CheckAndUncheckBtn
                      href="#"
                      onClick={() => setFieldValue('cargosIds', cargos, false)}
                    >
                      Marcar Todos
                    </CheckAndUncheckBtn>
                    <CheckAndUncheckBtn
                      href="#"
                      onClick={() => setFieldValue('cargosIds', [], false)}
                    >
                      Desmarcar Todos
                    </CheckAndUncheckBtn>
                  </Col>
                </Col>
              </Row>
              {!viewOnly
                && (
                <Row className="mt-4">
                  <Col xs={{ span: 6, offset: 3 }} md={{ span: 4, offset: 4 }}>
                    <Button
                      background={theme.colors.main}
                      color="#fff"
                      className="text-uppercase"
                      type="submit"
                      loading={loading}
                    >
                      Salvar
                    </Button>
                  </Col>
                </Row>
                )
              }
            </Form>
          )}
        </Formik>
      </ModalComponent>
    </>
  );
};
