import { Form, Formik } from 'formik';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Button } from '../../components/Button';
import { HeaderTitle } from '../../components/HeaderTitle';
import { Input } from '../../components/Input';
import api from '../../services/api';
import { HtmlEditor } from '../Use-Terms/components/Html-Editor';
import { LabelStyled } from '../Users/ModalImport/styles';
import { Container } from './styles';

interface IFormValues {
  assunto: string;
  corpo: string;
}

const validationSchema = Yup.object().shape({
  assunto: Yup.string().required('O campo Assunto é obrigatório'),
});

const emptyData: IFormValues = {
  assunto: '',
  corpo: '',
};

export const EmailSender = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [htmlContent, setHtmlContent] = useState<string>('');

  const handleOnHtmlEditorChange = (content: string) => {
    setHtmlContent(content);
  };

  const handleSubmit = (formValues: IFormValues) => {
    if (!htmlContent) {
      toast.error('O corpo do e-mail é obrigatório');
      return;
    }

    setIsLoading(true);

    api.post('correio', { ...formValues, corpo: htmlContent })
      .then(() => {
        toast.success('E-mail enviado com sucesso');
      })
      .catch(() => {
        toast.error('Erro ao enviar e-mail');
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Container>
      <HeaderTitle
        title="Envio de E-mails aos Colaboradores"
        text="Ao digitar o texto e enviar todos os colaboradores da empresa receberão seu e-mail."
      />

      <Formik
        initialValues={emptyData}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        <Form>
          <Row className="mt-3">
            <Col xs={12}>
              <Input type="text" name="assunto" label="Assunto" />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col xs={12}>
              <LabelStyled>
                Corpo do E-mail
              </LabelStyled>
              <HtmlEditor
                handleOnChange={handleOnHtmlEditorChange}
              />
            </Col>
          </Row>

          <Row className="mt-4">
            <Col md={{ span: 4, offset: 4 }}>
              <Button
                background="transparent"
                color="#fff"
                loading={isLoading}
                className="text-uppercase"
                type="submit"
              >
                Salvar
              </Button>
            </Col>
          </Row>
        </Form>
      </Formik>
    </Container>
  );
};
