import { ComponentType, ReactElement, useCallback, useEffect, useState } from 'react';
import { IconBaseProps } from 'react-icons';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { IoAdd } from 'react-icons/io5';
import { BiCopy, BiTrash } from 'react-icons/bi';
import { AiFillEye, AiOutlineEdit, AiOutlineQrcode } from 'react-icons/ai';
import { lighten } from 'polished';
import { Col, Row } from 'react-bootstrap';
import DOMPurify from 'dompurify';
import { Actions, Container, IconComponent, Item, List } from './styles';
import theme from '../../styles/theme';
import { Button } from '../Button';
import { HtmlContainer } from '../BoxItemGameDuplicate/styles';

export interface IItem {
  id: number;
  nome?: string;
  titulo?: string;
  extraInfo?: string;
  canEdit?: boolean;
  canDelete?: boolean;
  canPreview?: boolean;
  canDuplicate?: boolean;
  externo?: boolean;
}
interface Props {
  header: string;
  onShowFormModal?: () => void;
  messageEmpty?: string;
  onSelecetItem?: (id: number) => void;
  icon: ComponentType<IconBaseProps>;
  items: IItem[];
  onShowFormEdit?: (id: number) => void;
  onShowLinkModal?: () => void;
  onDuplicate?: (indexItem: number) => void;
  onDuplicateWithId?: (id: number) => void;
  selectedId?: number;
  superiorSelecionado?: boolean;
  onDeleteItem?: (id: number) => void;
  onPreview?: (id: number) => void;
  onDragEnd?: (resultIndex: number, resultDestination: number) => void;
  disabledButtonAdd?: boolean;
  displayTitle?: (item: IItem | any) => ReactElement | undefined;
}

export const BoxItemGame = ({
  header,
  icon: Icon,
  messageEmpty,
  onDeleteItem,
  onPreview,
  onDuplicate,
  onDuplicateWithId,
  onSelecetItem,
  onShowFormModal,
  onShowFormEdit,
  selectedId,
  superiorSelecionado,
  items,
  onDragEnd,
  disabledButtonAdd,
  displayTitle,
  onShowLinkModal,
}: Props) => {
  const [isShowCardHtml, setShowCardHtml] = useState<boolean>(header === 'Perguntas');

  const handleSelectItem = useCallback((id: number) => {
    if (onSelecetItem) {
      onSelecetItem(id);
    }
  }, [onSelecetItem]);

  const handleOnDragEnd = async (result: DropResult) => {
    if (!result.destination) return;
    if (onDragEnd) {
      onDragEnd(result.source.index, result.destination.index);
    }
  };

  const isHTML = (str: string) => {
    const doc = new DOMParser().parseFromString(str, 'text/html');
    return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
  };

  const useSanitizedHtml = (htmlString: string) => DOMPurify.sanitize(htmlString);

  const HtmlContent = (htmlString: string) => {
    const sanitizedHtml = useSanitizedHtml(htmlString);
    if (isHTML(sanitizedHtml)) {
      const blank = sanitizedHtml.split(' ')[0];
      const breakLine = sanitizedHtml.split('\n')[0];
      return <HtmlContainer style={{ color: 'black' }} dangerouslySetInnerHTML={{ __html: breakLine.length > 0 ? breakLine : blank }} />;
    }
    return <span>{htmlString}</span>;
  };

  return (
    <Container className="p-2 mt-3">
      <Row>
        <Col xs={12} md={{ span: 8, offset: 2 }}>
          <h6>{header}</h6>
        </Col>
        <Col xs={12} md={2} className="d-flex flex-column justify-content-center">
          {!disabledButtonAdd && (
          <Button
            type="button"
            background={
              !superiorSelecionado ? lighten(0.2, theme.colors.main) : theme.colors.success
            }
            color="#fff"
            disabled={!superiorSelecionado}
            onClick={onShowFormModal}
          >
            <IoAdd size={24} />
          </Button>
          )}
        </Col>
      </Row>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="items">
          {(provided) => (
            <List
              className="p-2"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {!superiorSelecionado && messageEmpty && <p>{messageEmpty}</p>}
              {items.length === 0
                && superiorSelecionado
                && (
                <p>
                  Clique em + para adicionar as informações gerais do
                  {` ${header.toLowerCase()}`}
                </p>
                )
              }
              {items && items.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={String(item.id)}
                  index={index}
                >
                  {(providedChild) => (
                    <>
                      <Item
                        className="d-flex"
                        ref={providedChild.innerRef}
                        {...providedChild.draggableProps}
                        {...providedChild.dragHandleProps}
                        onClick={() => handleSelectItem(item.id)}
                      >
                        <IconComponent className="p-2 d-flex align-items-center" isSelected={item.id === selectedId}>
                          <Icon />
                        </IconComponent>
                        <div
                          className="w-100 p-2 pb-5 border-dotted-bottom d-flex justify-content-between align-content-center"
                          style={{
                            flexFlow: 'column',
                          }}
                        >
                          {!isShowCardHtml && displayTitle && (<span>{displayTitle(item)}</span>)}
                          {isShowCardHtml && (HtmlContent(item.nome ? item.nome : item.titulo ? item.titulo : ''))}
                          { item.extraInfo && (
                            <span style={{ fontSize: '0.8rem', color: 'red' }}>
                              {item.extraInfo}
                            </span>
                          )}
                        </div>

                        <Actions className="d-flex justify-content-end">
                          {(onShowLinkModal && (item?.externo)) && (
                          <button
                            type="button"
                            title="QR Code"
                            onClick={() => {
                              onShowLinkModal();
                            }}
                          >
                            <AiOutlineQrcode />
                          </button>
                          )}
                          {(onDuplicate || onDuplicateWithId) && item?.canDuplicate && (
                          <button
                            type="button"
                            title="Duplicar"
                            onClick={() => {
                              if (onDuplicate) {
                                onDuplicate(index);
                              }

                              if (onDuplicateWithId) {
                                onDuplicateWithId(item.id);
                              }
                            }}
                          >
                            <BiCopy />
                          </button>
                          )}
                          {(onDeleteItem && (item.canDelete ?? true)) && (
                          <button type="button" title="Excluir" onClick={() => onDeleteItem(item.id)}>
                            <BiTrash />
                          </button>
                          )}
                          {(onShowFormEdit && (item.canEdit ?? true)) && (
                          <button type="button" title="Editar" onClick={() => onShowFormEdit(item.id)}>
                            <AiOutlineEdit />
                          </button>
                          )}
                          {(onPreview && (item.canPreview ?? true)) && (
                          <button type="button" title="Visualizar" onClick={() => onPreview(item.id)}>
                            <AiFillEye />
                          </button>
                          )}
                        </Actions>
                      </Item>
                      {provided.placeholder}
                    </>
                  )}
                </Draggable>
              ))}
            </List>
          )}
        </Droppable>
      </DragDropContext>

    </Container>
  );
};
